<template>
    <div class="detail" :style="{'padding-top': '48px'}">
        <div class="detail__header">
            <div class="control-btns" v-if="$route.params.id !== 'new'">
                <router-link :to="detailPath+'/move-article'" class="control-btns__btn has-hint">
                    <svg-icon :name="'move'" :width="24" :height="24"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Переместить
                    </div>
                </router-link>
                <router-link :to="detailPath+'/delete'" class="control-btns__btn has-hint" v-if="detail.status !== 'public'">
                    <svg-icon :name="'trash'" :width="24" :height="24"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Удалить
                    </div>
                </router-link>
                <router-link :to="detailPath+'/preview'" class="control-btns__btn has-hint">
                    <svg-icon :name="'eye'" :width="24" :height="24"></svg-icon>
                    <div class="hint bottom-center-direction">
                        Предпросмотр
                    </div>
                </router-link>
            </div>
            <div v-else class="control-btns"></div>
            <div class="right">
                <auto-save-preloader
                    v-if="$route.name === 'detail' || $route.name === 'detail-edit'"
                    @close="close()" :is-save="isAutoSave" :is-ok="isAutoSaveOk"/>
            </div>
        </div>
        <not-success v-if="detail.id && detail.notSuccess"></not-success>
        <router-view v-else-if="detail.id && currentAuthor.name && currentAuthor" :detail="detail" :detail-path="detailPath"/>
        <detail-edit v-else-if="$route.params.id === 'new' && currentAuthor.name && currentAuthor" :detail="detail" :detail-path="detailPath" />
    </div>
</template>

<script>
import {
    mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import { scrollTo } from '@/helpers/animate';
import SvgIcon from '@/components/SvgIcon.vue';
import DetailEdit from '@/components/rules/DetailEdit.vue';
import NotSuccess from '@/components/detail/NotSuccess.vue';
import activeCategories from '@/helpers/composables/activeCategories';
import { copyTextToClipboard } from '@/helpers/util/helper';
import AutoSavePreloader from '@/components/AutoSavePreloader.vue';

export default {
    name: 'RuleDetail',
    components: {
        SvgIcon,
        DetailEdit,
        NotSuccess,
        AutoSavePreloader,
        // Модалки
    },
    props: {
        isBottomHeader: {
            default() {
                return false;
            },
        },
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    data() {
        return {
            timerSave: false,
            bigImages: false, // Тумблер переключения отображения изображений
            alreadyEditing: false, // Правило уже редактируют
            frontPath: process.env.VUE_APP_PATH_FRONT,
        };
    },
    mounted() {
        if (this.authors.length <= 0) {
            this.getAuthors();
        }
        if (this.activeSection && this.activeSection.author_id) {
            const author = this.authors.find((item) => +item.id === +this.activeSection.author_id);
            if (author) {
                this.$store.commit('articles/setCurrentAuthor', author);
            }
        }
        if (this.$route.params.id !== 'new' && this.$route.params.id) {
            this.getArticle({ id: this.$route.params.id });
            this.setShowButtons(true);
        }
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            notify: (state) => state.popups.notify,
            switches: (state) => state.switches.items,
            detail: (state) => state.articles.detail,
            form: (state) => state.articles.form,
            showButtons: (state) => state.articles.showButtons,
            isAutoSave: (state) => state.articles.isAutoSave,
            isAutoSaveOk: (state) => state.articles.isAutoSaveOk,
            autoSaveLog: (state) => state.articles.autoSaveLog,
            user: (state) => state.auth.user,
            authors: (state) => state.articles.authors,
            currentAuthor: (state) => state.articles.currentAuthor,
        }),
        ...mapGetters('auth', [
        ]),
        isEdit() {
            return this.$route.name === 'detail-edit';
        },
        isMovingRule() {
            return this.$route.name === 'detail-move';
        },
        detailPath() {
            if (this.$route.params.id !== 'new' && this.$route.params.id) {
                return this.detail.id
                    ? `/sid${this.detail.category_id}/${this.detail.id}`
                    : '';
            }
            return `/sid${this.activeSection.id}/new`;
        },
    },
    methods: {
        ...mapActions('articles', [
            'getArticle',
            'getAuthors',
            'saveNew',
            'save',
        ]),
        ...mapMutations('articles', [
            'setFormField',
            'setFormFieldError',
            'setShowButtons',
            'setIsAutoSave',
            'setIsAutoSaveOk',
            'setAutoSaveLog',
        ]),
        ...mapMutations('switches', [
            'offSwitch',
            'onSwitch',
        ]),
        scrollToUp() {
            scrollTo('up');
        },
        offEdit() {
            this.offSwitch(this.switches.ruleEdit.name);
        },
        onEdit() {
            this.onSwitch(this.switches.ruleEdit.name);
            this.offMoveRule();
        },
        onMoveRule() {
            this.onSwitch(this.switches.moveRule.name);
            this.offEdit();
        },
        offMoveRule() {
            this.offSwitch(this.switches.moveRule.name);
        },
        close() {
            this.$router.push(`/sid${this.activeSection.id}/`);
        },
        changeIsAutoSave() {
            this.setIsAutoSave(false);
            this.setIsAutoSaveOk(true);
            setTimeout(() => {
                this.setIsAutoSaveOk(false);
            }, 3000);
        },
        copyTextToClipboard(value) {
            this.$toast.show(this.notify.ruleLink);
            copyTextToClipboard(value);
        },
    },
};
</script>

<style lang="sass">
.detail
    background-color: #F9FCFF
    min-height: 100vh
    .preview
        &__nav
            &-item
                display: flex
                align-items: center
                padding: 8px 0
                font-size: 16px
                line-height: 26px
                border-radius: 8px
                &.active
                    background-color: #fff
                    color: $black1 !important
        &__ttl
            height: 52px
            display: flex
            align-items: center
            color: $black1
    .tumbler
        width: 56px
        height: 24px
        background-color: $black1
        border-radius: 24px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 7px
        cursor: pointer
        position: relative
        margin-right: 60px

        &:after
            content: ''
            display: block
            position: absolute
            left: 1px
            width: 28px
            height: 22px
            background-color: $gray
            border-radius: 22px
            transition: all .15s ease

        .mini
            color: #fff
            position: relative
            z-index: 1
            transition: all .15s ease

        .big
            position: relative
            z-index: 1
            transition: all .15s ease

        &.active
            .mini
                color: $gray

            .big
                color: #fff

            &:after
                left: 27px
        &.--icon
            width: 40px
            transition: all .15s ease
            &:after
                content: none
            &:hover
                background-color: $blue10
            .icon
                width: 20px
                height: 20px
                border-radius: 16px
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2)
                position: absolute
                background-color: #fff
                left: 2px
                top: 2px
                display: flex
                align-items: center
                justify-content: center
                color: $gray50
                transition: all .15s ease
            &.active
                background-color: $yellow
                .icon
                    left: 18px
                    color: $yellow
    .slider
        width: 576px
        margin: 0
        position: relative
        display: none
        .swiper-notification
            display: none
        &__inner
            overflow: hidden
            border-radius: 4px
        &__counter
            position: absolute
            width: 42px
            height: 16px
            display: flex
            justify-content: center
            align-items: center
            right: 8px
            bottom: 8px
            font-weight: bold
            color: #fff
            background-color: $gray
            font-size: 10px
            border-radius: 16px
            z-index: 1
        .viewer-elem
            height: auto
            display: flex
            align-items: center
            margin: 0 !important
            width: 576px !important
            &:hover
                border-color: rgba($gray, 0.1)
        &__actions
            width: 616px
            position: absolute
            top: 0
            left: -20px
            top: 181px
        &__nav-button
            position: absolute
            top: 181px
            z-index: 3
            border: none
            outline: none
            background-color: $yellow
            width: 40px
            height: 40px
            border-radius: 40px
            display: flex
            align-items: center
            justify-content: center
            transition: all .15s ease
            &:after
                content: ''
                display: block
                width: 32px
                height: 32px
                background-image: url(../assets/img/chevron.svg)
                margin-bottom: -2px
            &.prev
                left: -20px
            &.next
                right: -20px
                &:after
                    transform: rotate(180deg)
                    margin-top: -3px
            &:hover
                background-color: #FFE481
            &:active
                background-color: #e6c04e
            &--next
                svg
                    transform: rotate(180deg) translateX(-2px)
    .rte
        counter-reset: images
        font-family: 'monts' !important
        position: relative
        .youtube
            position: relative
            display: flex
            align-items: center
            padding: 8px 0
            margin-bottom: 24px
            .title
                display: flex
                align-items: center
                font-size: 16px
                line-height: 26px
                font-weight: bold
            .subtitle
                color: $gray50
                font-size: 12px
                line-height: 16px
        .article-title
            width: 656px !important
            margin-left: -40px
            margin-bottom: 16px
            textarea
                font-size: 28px
                line-height: 32px
                font-weight: bold
                padding: 16px 40px
                border-color: transparent
                &:focus
                    border-color: $accent
                &::-webkit-input-placeholder
                    font-weight: bold
                &:-moz-placeholder
                    font-weight: bold
                &::-moz-placeholder
                    font-weight: bold
                &:-ms-input-placeholder
                    font-weight: bold
        .article-ttl
            min-height: 64px
            padding: 16px 40px
            width: 656px
            margin-left: -40px
            font-size: 28px
            line-height: 32px
            word-break: break-word
            font-weight: bold
        .blockquote,.r-block
            width: 656px
            margin: 16px 0
            margin-left: -40px
            padding: 32px 40px
            border-radius: 8px
            background-color: $yellow10
            position: relative
            &:after
                content: ''
                display: block
                width: 55px
                height: 44px
                background-image: url(../assets/img/quote.svg)
                background-repeat: no-repeat
                background-size: contain
                position: absolute
                right: 32px
                top: 24px
            &__text
                width: 527px
                position: relative
                font-size: 16px
                line-height: 26px
                margin-left: -8px
                margin-top: -8px
                padding: 8px
                border: 1px solid transparent
                border-radius: 8px
                min-height: 58px
                transition: all .15s ease
                margin-bottom: 12px
                p
                    &:empty:before
                        content: '123123'
                &:hover
                    background-color: #F9EECC
            &.has-focus:not(.--r)
                .blockquote__text
                    border-color: $text-color
                    background-color: transparent !important
            &__photo
                width: 48px
                height: 48px
                border-radius: 48px
                margin-right: 8px
                display: flex
                align-items: center
                justify-content: center
                overflow: hidden
                background-color: $yellow
                &:after
                    content: attr(data-char)
                    font-weight: bold
                    font-size: 16px
                    position: absolute
                img
                    height: 100%
                    width: auto
                    position: relative
                    z-index: 1
                &.noimage
                    img
                        display: none
            &__profile
                display: flex
                align-items: center
                padding: 0 8px
                .name
                    font-size: 16px
                    line-height: 26px
                    font-weight: bold
                    &:after
                        content: attr(data-name)
                .role
                    font-size: 12px
                    line-height: 16px
                    color: $gray50
                    margin-top: 4px
                    &:after
                        content: attr(data-role)
                .rlink
                    font-size: 16px
                    line-height: 26px
                    font-weight: bold
                    display: inline
                .link
                    font-size: 16px
                    line-height: 26px
                    font-weight: bold
                    display: inline
                    &:after
                        content: attr(data-link)

            &__info
                margin-right: auto
            &.is-empty
                .blockquote__text
                    &:after
                        content: 'Введите текст прямой речи... '
                        font-size: 16px
                        line-height: 26px
                        color: $gray50
                        position: absolute
                        top: 8px
                        left: 8px
                        pointer-events: none
            .r-block
                &__photo
                    background-image: url(../assets/img/photo.png)
                &__text
                    &:after
                        content: none !important
                    &:hover
                        background-color: transparent !important
        .is-editor-empty:first-child::before
            content: attr(data-placeholder)
            pointer-events: none
            font-size: 16px
            line-height: 25px
            color: $gray50
            position: absolute
            float: left
        [data-comment]
            background-color: $blue10
            user-select: all
        em
            font-style: normal !important
        i
            font-style: normal !important
        h1
            font-size: 28px
            line-height: 32px
            margin: 32px 0 16px
            font-weight: bold
        blockquote
            padding-left: 22px
            border: none
            border-radius: 0
            display: block
            box-sizing: border-box
            margin: 20px 0
            position: relative
            &:after
                content: ''
                position: absolute
                width: 6px
                height: 100%
                left: 0
                background-color: $yellow
                top: 0
                border-radius: 8px
        h2
            font-size: 22px !important
            line-height: 25px !important
            margin: 24px 0 12px
            font-weight: bold
            strong
                font-size: 26px !important
                line-height: 125% !important
            em
                font-size: 26px !important
                line-height: 125% !important
            & + p
                margin-top: 8px
                .image-wrapper,.other-file,.preloader,.scheme
                    margin-top: 12px
        h3
            font-size: 18px !important
            line-height: 21px !important
            margin: 12px 0 8px
            font-weight: bold
            strong
                font-size: 20px !important
                line-height: 125% !important
            em
                font-size: 20px !important
                line-height: 125% !important
            & + p
                .image-wrapper,.other-file,.preloader,.scheme
                    margin-top: 12px
        hr
            margin: 20px 0
            height: 1px
            border: none !important
            width: 100%
            background-color: #E5E5E5
        ul
            margin: 16px 0
            li
                padding-left: 24px
                position: relative
                blockquote,pre
                    margin-bottom: 0
                    margin-left: 6px
                    p
                        margin-left: 0
                p
                    margin: 0 !important
                    line-height: 20px !important
                    &:nth-child(1)
                        margin-top: 0
                &:before
                    content: ''
                    width: 6px
                    height: 6px
                    top: 7px
                    border-radius: 6px
                    background-color: $gray
                    position: absolute
                    left: 0
                    font-size: 16px
                & + li
                    margin-top: 12px
        ol
            margin: 16px 0
            padding-left: 24px
            list-style: none
            counter-reset: ol
            li
                position: relative
                blockquote,pre
                    margin-bottom: 0
                    p
                        margin-left: 0
                p
                    line-height: 26px !important
                    margin: 0 !important
                    &:nth-child(1)
                        margin-top: 0
                &::before
                    font-weight: bold
                    counter-increment: ol
                    content: counters(ol,".") ". "
                    font-size: 16px
                    line-height: 26px
                    width: 24px
                    position: absolute
                    left: -24px
                & + li
                    margin-top: 12px
            ol
                margin-bottom: 8px
                padding-left: 32px
                li
                    &::before
                        width: 32px
                        left: -32px
                ol
                    margin-bottom: 8px
                    padding-left: 42px
                    li
                        &::before
                            width: 42px
                            left: -42px
        p
            font-size: 16px !important
            line-height: 26px !important
            margin: 16px 0
            & + p
                margin-top: 16px
        .image-wrapper
            width: 576px
            height: auto
            display: flex
            align-items: center
            justify-content: center
            margin: 16px 0
            background-color: #000
            border-radius: 8px
            border: 1px solid $gray20
            position: relative
            overflow: hidden

            &:hover
                border-color: $default-color
        .image,.file-preloader
            position: relative
            user-select: text
            line-height: 64px !important
            font-size: 54px !important
            text-align: left
            padding: 0
            display: block
            height: 100%
            width: 576px
            margin-right: 0
            object-fit: cover
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            cursor: pointer
            transition: all .15s ease
        .file-preloader
            vertical-align: unset !important
            background-color:  #F9FCFF !important
            position: relative
            height: 280px
            margin: 16px 0
            &:after
                position: absolute
                top: calc(50% - 20px)
                left: calc(50% - 20px)
                content: ''
                width: 40px
                background-size: contain
                display: block
                height: 40px
                background-image: url(../assets/img/preloader.svg)
                animation: preloader-rotate 1.5s linear infinite
            &:hover
                border-color: rgba($gray, 0.1)
    .close-header
        height: 48px
        width: 100%
        display: flex
        justify-content: flex-end
        align-items: center
        padding: 0 32px
        min-width: $container-width
        position: relative
        z-index: 10
        .close
            cursor: pointer
            position: fixed
            top: 56px
            z-index: 10
    .pagination
        width: 100%
        display: flex
        align-items: center
        height: 160px
        justify-content: space-between
        padding: 0 32px

        svg
            position: absolute

        a
            position: relative
            display: flex
            align-items: center

        .prev
            svg
                left: -16px

        .next
            text-align: right

            svg
                transform: rotate(180deg)
                right: -16px

    &__moving-header
        height: 48px
        background-color: $black1
        position: fixed
        top: 48px
        left: 0
        right: 0
        width: 100%
        display: flex
        justify-content: space-between
        z-index: 6
        padding: 0 32px 0 24px
        min-width: $container-width
        align-items: center

        .close
            color: $gray60
            transition: all .15s ease
            cursor: pointer

            &:hover
                color: #fff

        &.--plug
            position: static

    &__footer
        display: flex
        align-items: center
        justify-content: space-between
        height: 48px
        padding: 0 32px
        border-top: 1px solid $gray20
        margin-top: 120px
        .l
            display: flex
            align-items: center

            .date
                margin-right: 17px
                position: relative

                &:after
                    content: ''
                    position: absolute
                    right: -9px
                    background-color: $accent
                    top: 4px
                    width: 1px
                    height: 8px

            .tooltip__content
                .date
                    &:after
                        background-color: $gray20

    &__controls
        height: 48px
        position: fixed
        top: 48px
        width: 100%
        min-width: $container-width
        left: 0
        right: 0
        display: flex
        justify-content: space-between
        z-index: 5
        background-color: $blue
        border-bottom: 1px solid $gray20
        padding: 0 32px
        align-items: center
        .tumbler
            background-color: $separator
            margin-right: 0

            &:after
                background-color: #fff

            .mini
                color: $text-color
            .big
                color: $gray50

            &.active
                .mini
                    color: $gray50

                .big
                    color: $gray
        .writer
            display: flex
            align-items: center
            font-weight: bold
            cursor: pointer
            transition: all .15s ease
            .icon
                width: 24px
                height: 24px
                border-radius: 24px
                background-color: $yellow
                font-weight: bold
                display: flex
                align-items: center
                justify-content: center
                font-size: 12px
                line-height: 15px
                margin-right: 4px
                color: $gray
                transition: all .15s ease

            &:hover
                color: $accent
            &.--lock
                cursor: default
            &.--lock:hover
                color: $gray
            &.--assign
                color: $accent

                .icon
                    background-color: $accent
                    color: #fff
                &:hover
                    color: $accent-hover
                    .icon
                        background-color: $accent-hover

        .editor-buttons
            display: flex
            align-items: center
            position: relative
            .tumbler
                position: absolute
                left: -104px
            .editor-btn
                width: 32px
                height: 32px
                display: flex
                align-items: center
                justify-content: center
                border-radius: 4px
                transition: all .15s ease
                cursor: pointer
                margin-right: 4px

                &:hover
                    background-color: $separator

                &.active
                    background-color: $blue10
                    color: $accent

                &.border-right
                    margin-right: 33px
                    position: relative

                    &:after
                        content: ''
                        width: 1px
                        height: 32px
                        position: absolute
                        right: -17px
                        background-color: $gray20
                        top: 0
            &.--disabled
                .editor-btn
                    color: $gray50 !important
                    background-color: transparent !important

        .plug
            width: 400px

        .rule-btns
            min-width: 400px
            display: flex
            align-items: center
            justify-content: flex-end

        .close
            width: 32px
            height: 32px
            position: absolute
            right: 32px
            cursor: pointer
        .left,.right
            width: 350px
        .right
            display: flex
            align-items: center
            justify-content: flex-end
        .center
            display: flex
            align-items: center
            justify-content: center
        &.--preview
            .close
                position: static
        &--plug
            height: 48px
            &.--preview
                background-color: $separator

    .content
        width: 100%
        min-width: $container-width
        padding-top: 0
        .container
            margin-bottom: 150px
            padding-top: 24px
            width: 1376px
            position: relative
            margin: 0 auto
            &.--moving-rule
                padding-top: 0
        &.preview-page
            background-color: $separator !important
            min-height: 1900px
            padding-bottom: 194px
    .widgets
        .widget
            width: 320px
            height: auto
            padding: 16px
            box-shadow: 0px 0px 6px rgba(148, 181, 225, 0.35)
            background-color: #fff
            margin-bottom: 16px
            font-family: 'monts' !important
            &.--author
                box-shadow: 0px 0px 12px rgba(36, 99, 184, 0.3)
    .adv
        .step
            display: flex
            padding-bottom: 16px
            margin-bottom: 12px
            &:last-child
                .l
                    &:before
                        content: none
            &:first-child
                .l
                    &:before
                        height: 141px
                        background-image: url(../assets/img/arrow-mini.svg)
            .l
                margin-right: 8px
                position: relative
                &:before
                    position: absolute
                    height: 245px
                    width: 32px
                    left: 0
                    content: ''
                    top: 32px
                    background-image: url(../assets/img/arrow.svg)
                    background-position: center
                    background-repeat: no-repeat
                    background-size: contain
                .start-icon
                    width: 32px
                    height: 32px
                    display: flex
                    align-items: center
                    justify-content: center
                    background-color: $yellow
                    border-radius: 0 0 8px 0
                .icon
                    width: 32px
                    height: 32px
                    background-color: $yellow
                    color: $black1
                    font-weight: bold
                    border-radius: 32px
                    font-size: 18px
                    display: flex
                    align-items: center
                    justify-content: center
            .r
                .ttl
                    min-height: 32px
                    display: flex
                    align-items: center
                .image
                    width: 264px
                    height: 144px
                    background-position: center
                    background-size: cover
                    background-repeat: no-repeat
                    border-radius: 8px
                    &1
                        background-image: url(../assets/img/adv_1.webp)
                    &2
                        background-image: url(../assets/img/adv-2.webp)
                    &3
                        background-image: url(../assets/img/adv_3.webp)
                    &4
                        background-image: url(../assets/img/adv_4.webp)
                    &5
                        background-image: url(../assets/img/adv_1.webp)
    .aside-sticky-block
        width: 23.4%
        position: absolute
        margin-right: 32px
        margin-left: 0
        &.--preview
            width: 312px
            margin-right: 0
        &.--widgets
            right: 0
            width: 320px !important
            top: 24px
            margin: 0
        &.--adv
            right: 0
            width: 304px !important
            top: 24px
            margin: 0
        .aside
            width: auto
            min-width: auto

        &.--moving-rule
            margin-left: 17px
            width: auto
            min-height: calc(100vh - 96px)
            border-right: 1px solid $blue10

            .aside-nav
                background-color: $blue

                &__link
                    &.active
                        background-color: $yellow
                        color: $gray

                    &.current
                        &:after
                            content: ''
                            width: 30px
                            height: 17px
                            border: 9px solid transparent
                            border-left: 15px solid $yellow
                            position: absolute
                            left: -17px
                            display: block
                            box-sizing: border-box

    .container__right
        max-width: 704px
        width: 704px
        margin: 0 auto
        min-height: 800px
        padding: 32px 64px 120px
        box-shadow: 0px 0px 6px rgba(148, 181, 225, 0.35)
        background-color: #fff
        &.--preview
            border-radius: 8px
            box-shadow: none
            .inp
                word-break: break-word
            .table-of-contents
                display: flex
                align-items: center
                justify-content: space-between
                width: 656px
                margin-left: -40px
                height: 64px
                border-radius: 8px
                background-color: $yellow10
                padding: 0 40px
                font-size: 22px
                line-height: 24px
                font-weight: bold
                cursor: pointer
                .icon
                    transform: rotate(-90deg)
            .article-ttl
                padding: 24px 40px 8px 40px
                margin-bottom: 0 !important
            .article-info
                height: 56px
                display: flex
                align-items: center
                justify-content: space-between
                margin-right: -40px
                .l
                    display: flex
                    align-items: center
                    color: $gray50
                    font-size: 12px
                    line-height: 16px
                    .time
                        margin-right: 18px
                        position: relative
                        &::after
                            content: ''
                            width: 2px
                            height: 2px
                            border-radius: 2px
                            background-color: $gray50
                            position: absolute
                            left: calc(100% + 8px)
                            top: 8px
                    .views
                        display: flex
                        align-items: center
                .r
                    display: flex
                    align-items: center
                    .video-link
                        display: flex
                        align-items: center
                        padding: 8px 16px
                        background-color: $blue10
                        border-radius: 8px
                        font-size: 12px
                        line-height: 16px

        &.--moving-rule
            min-height: auto
            height: auto
            background-color: transparent
            box-shadow: none
            padding: 0
            padding-top: 48px
            height: auto
            border-left: 0

        .move-block
            display: flex
            flex-direction: column
            align-items: center
            box-shadow: 0px 0px 6px rgba(148, 181, 225, 0.35)
            background-color: #fff
            height: auto
            padding-top: 48px
            padding-bottom: 48px
            .authors
                width: 576px
                margin: 0 auto
                justify-content: space-between
                .article-author
                    width: 256px
            .destination
                display: flex
                width: 576px
                justify-content: space-between
                align-items: center

                &__item
                    width: 256px
                    height: 48px
                    background-color: $blue10
                    padding: 16px
                    border-radius: 4px

                    &.error
                        background-color: $red
                        color: #fff

    &__header
        height: 48px
        background-color: $black2
        position: fixed
        top: 0
        left: 0
        right: 0
        width: 100%
        display: flex
        justify-content: space-between
        z-index: 6
        padding: 0 32px
        min-width: $container-width

        .control-btns
            display: flex
            align-items: center
            width: 200px

            &__btn
                width: 40px
                height: 48px
                color: $gray60
                display: flex
                align-items: center
                justify-content: center
                cursor: pointer
                transition: all .15s ease

                &:hover
                    color: #fff

                &.router-link-active
                    color: $yellow
                    background-color: $black1

        .center
            .plug
                min-width: 63px

            .rule-id
                cursor: pointer
                transition: all .15s ease

                &:hover
                    color: #fff

            .title
                max-width: 768px
                padding: 0 14px
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                margin: 0 8px

            .title-input
                max-width: 768px !important
                min-width: 266px
                margin: 0 8px

                .inp-s
                    text-align: center
                    padding: 0
                    font-size: 18px
                    height: 32px
                    border-radius: 40px
                    border: none
                    transition: all .15s ease

                    &-wrap.fill
                        .inp-s
                            background-color: $black2
                            color: $gray20

                        &:hover
                            .inp-s
                                background-color: $black3

                    &:focus
                        background-color: #fff !important
                        color: $gray !important

                    &-counter
                        display: none

        .right
            width: 200px
            display: flex
            align-items: center
            justify-content: flex-end

            .close
                color: $gray60
                cursor: pointer
                transition: all .15s ease

                &:hover
                    color: #fff
.chief-comment
    width: 704px
    margin: 0 auto
    padding: 16px
    background-color: #fff
    box-shadow: 0px 0px 16px rgba(176, 197, 222, 0.5)
    border-radius: 4px
    font-family: Arial !important
    .comment-text
        font-size: 12px
        line-height: 19px
    .top
        display: flex
        align-items: flex-start
        justify-content: space-between
        .left
            display: flex
            align-items: center
            .char
                width: 36px
                height: 36px
                display: flex
                align-items: center
                justify-content: center
                text-transform: uppercase
                background-color: $yellow
                border-radius: 36px
                font-size: 20px
                line-height: 24px
                margin-right: 8px
            .name
                font-size: 12px
                line-height: 14px
                font-weight: bold
                font-family: Arial
            .date
                font-family: Arial
                font-size: 10px
                line-height: 16px
                color: $gray60
        .right
            .close,.trash
                svg
                    transition: all .15s ease
                    cursor: pointer
                    &:hover
                        color: $gray !important
.treeview
    &__wrapper
        position: fixed !important
        top: 72px
        overflow-y: auto
        visibility: hidden
        z-index: 2
        &:hover
            visibility: visible
    .text-size-18
        padding-left: 32px
        visibility: visible

    &__list
        padding-left: 32px
        margin-top: 8px
        padding-top: 16px
        border-top: 1px solid $separator
        position: relative
        visibility: visible
        background-color: $blue

    &__item
        width: 100%
        margin-bottom: 12px
        font-family: Arial
        font-size: 14px
        line-height: 16px
        width: 100%
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        cursor: pointer
        &.level2
            padding-left: 16px
        &.level3
            padding-left: 32px
        .active
            font-weight: bold
            display: flex
            align-items: center

            &:after
                content: ''
                display: block
                position: absolute
                left: 0
                width: 22px
                height: 4px
                border-radius: 0 2px 2px 0
                background-color: $accent
.article-author
    display: flex
    align-items: center
    &.--ghost
        .photo
            background: $separator
        .name
            width: 200px
            height: 26px
            background-color: $separator
            border-radius: 4px
        .cat
            width: 106px
            height: 16px
            background: $separator
            border-radius: 4px
    .photo
        background-image: url(../assets/img/photo.png)
        width: 48px
        height: 48px
        border-radius: 48px
        overflow: hidden
        margin-right: 8px
    .name
        font-size: 16px
        line-height: 25px
        font-weight: bold
    .cat
        font-size: 12px
        line-height: 16px
        color: $gray50
</style>
