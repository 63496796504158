<template>
<div class="aside__sticky" id="aside-sticky">
    <div class="aside-nav">
        <div class="aside-nav__wrapper">
            <draggable :forceFallback="true"
                        tag="ul"
                        @start="dragStart"
                        @end="dragSave"
                        :move="dragStatus"
                        :animation="150"
                        :touchStartThreshold="3"
                        :delay="10"
                        class="aside-nav__list"
                        :disabled="canDrag"
                        v-model="sections">
                <li class="aside-nav__item" v-for="item in sections" :key="item.id">
                    <router-link
                        v-if="switches.editable.value || switches.adminEditable.value"
                        @click="closeSearch()"
                        class="aside-nav__link"
                        :to="'/sid'+item.id+'/'"
                        :class="{'router-link-active': item.id ===  activeSection.id}">
                        <div class="drag-icon">
                            <svg-icon :name="'drag'" :width="8" :height="12" class="text-color-gray50"></svg-icon>
                        </div>
                        <div class="name has-status">
                            <span class="cat-name">{{item.name}}</span>
                        </div>
                    </router-link>
                    <router-link
                        v-else
                        @click="closeSearch()"
                        class="aside-nav__link"
                        :to="'/sid'+item.id+'/'"
                        :class="{'router-link-active': item.id ===  activeSection.id}">
                        <div class="name has-status" :class="{
                            'updated': activeSection.id !== item.id && showLabel(item)
                        }">
                            <span class="cat-name">{{item.name}}</span>
                        </div>
                        <div class="count">
                            {{ item.count }}
                        </div>
                    </router-link>
                </li>
            </draggable>
        </div>
    </div>
    <div class="aside-nav__block mt16" v-if="switches.editable.value">
        Пустые категории<br>
        не отображаются на сайте
    </div>
    <div class="aside-nav__block mt16" v-if="switches.editable.value">
        Для добавления категории<br>
        обратитесь к программистам
    </div>
</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { VueDraggableNext as draggable } from 'vue-draggable-next';
import SvgIcon from '@/components/SvgIcon.vue';
import activeCategories from '@/helpers/composables/activeCategories';

export default {
    name: 'AsideSections',
    components: {
        draggable,
        SvgIcon,
    },
    created() {},
    mounted() {
    },
    data() {
        return {
            drag: false,
            disabled: false,
            hoverClass: false,
            appPath: process.env.VUE_APP_PATH_BACK,
        };
    },

    setup() {
        const { activeSection } = activeCategories();
        return {
            activeSection,
        };
    },
    computed: {
        ...mapState({
            popups: (state) => state.popups.items,
            switches: (state) => state.switches.items,
            user: (state) => state.auth.user,
        }),
        ...mapGetters({
        }),
        sections: {
            get() {
                return this.$store.state.articles.sections || [];
            },
            set(value) {
                this.$store.commit('articles/setSections', value);
            },
        },
        canDrag() {
            return !(this.switches.editable.value || this.switches.adminEditable.value);
        },
    },
    methods: {
        ...mapActions('articles', [
            'setSectionsSort',
        ]),
        ...mapActions('popups', [
            'open',
        ]),
        closeSearch() {
            this.$store.commit('articles/setIsSearch', false);
        },
        dragSave() {
            if (this.drag) {
                this.setSectionsSort();
                this.drag = false;
            }
        },
        dragStart() {
        },
        dragStatus() {
            this.drag = true;
        },
        showLabel() {
            return false;
        },
    },
};
</script>

<style lang="sass">

</style>
