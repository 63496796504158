import { Mark } from '@tiptap/vue-3';
// import { updateMark, removeMark, pasteRule } from 'prosemirror-commands';
import { markPasteRule } from '@tiptap/core';
// import { getAttributes } from '@tiptap/html';
import { Plugin, PluginKey } from 'prosemirror-state';

const Link = Mark.create({

    name: 'link',
    inclusive: false,

    addOptions() {
        return {
            openOnClick: true,
            target: '_blank',
        };
    },

    addAttributes() {
        return {
            href: {
                default: null,
            },
            target: {
                default: null,
            },
        };
    },
    parseHTML() {
        return [
            {
                tag: 'a[href]',
                getAttrs: (dom) => ({
                    href: dom.getAttribute('href'),
                    target: dom.getAttribute('target'),
                }),
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['a', {
            href: HTMLAttributes.href,
            rel: 'noopener noreferrer nofollow',
            target: this.options.target,
        }, 0];
    },
    addCommands() {
        return {
            link: (attributes) => ({ chain, state }) => {
                const { selection } = state;
                const c = chain()
                    .setMark(this.name, attributes)
                    .setMeta('preventAutolink', true);
                if (selection.ranges[0].$from.pos === selection.ranges[0].$to.pos) {
                    c.insertContent(attributes.href);
                }
                return c.run();
            },
            unsetLink: () => ({ chain, state }) => {
                const { $from, $to } = state.selection;
                let hasLink = false;

                function isLink(str) {
                    // Regular expression to match URLs
                    const urlPattern = /^(https?|ftp|http):\/\/[^\s/$.?#].[^\s]*$/i;
                    return urlPattern.test(str);
                }
                // Check if there's only one link in the selected content
                state.doc.nodesBetween($from.pos, $to.pos, (node) => {
                    if (isLink(node.text)) {
                        hasLink = true;
                    }
                });

                // If there's only one link, remove it
                if (hasLink) {
                    chain()
                        .extendMarkRange('link')
                        .deleteSelection()
                        .run();
                } else {
                    chain()
                        .unsetMark(this.name, { extendEmptyMarkRange: true })
                        .setMeta('preventAutolink', true)
                        .run();
                }
                return true;
            },
        };
    },
    addPasteRules() {
        return [
            markPasteRule({
                find: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
                type: this.type,
                getAttributes: (match) => ({
                    href: `${match}`,
                }),
            }),
        ];
    },

    addProseMirrorPlugins() {
        if (!this.options.openOnClick) {
            return [];
        }

        return [
            new Plugin({
                key: new PluginKey('link'),
                props: {
                    handleClick: (view, pos, event) => {
                        const { schema } = view.state;
                        const attrs = this.editor.getAttributes(view.state, schema.marks.link);
                        const parent = event.target.closest('a');
                        if (
                            (attrs.href && event.target instanceof HTMLAnchorElement && !event.target.classList.contains('rlink'))
                            || (parent && parent.attributes.href && parent.attributes.target && !parent.classList.contains('rlink'))
                        ) {
                            document.dispatchEvent(new CustomEvent('openlinkedit', { detail: attrs.href ? attrs.href : parent.attributes.href.value }));
                            event.stopPropagation();
                        }
                    },
                },
            }),
        ];
    },
});

export default Link;
